/**
*
*   Home Subnav
*       - The Draggable Subnav on the Homepage
*
**/
'use client';


import 'aos/dist/aos.css';
import AOS from 'aos';
import Config from '@/config';
import Button from '@/components/button';
import Image from '@/components/image';
import { Link } from '@/components/link';
import { useState, useLayoutEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';

//Build the Element
export default function Subnav({ title=false, nav=[], button={}, text=false, ...props }){

    //Translations
    const t = useTranslations();

    //Subnav Ref
    const ref = useRef();

    //Default Button Settings
    button = {
        type: 'dark',
        size: 'narrow',
        inverted: true,
        ...button
    }

    //Return
    return (
        <section id="subnav" className={ text ? 'text' : '' } ref={ ref }>
            <div className="container">
                { title && (
                    <header>
                        <div>
                            <h3>{ title }</h3>
                        </div>
                    </header>
                )}
                <nav>
                    <div>
                        {
                            nav.map( (row, i)=>{

                                //Return Text Link
                                if( text ) return (
                                    <Link key={ i } href={ row.href }  className={ ( row?.current ? 'current' : '' ) }>
                                        <span>{ row.title }</span>
                                    </Link>
                                );

                                //Return Button Link
                                return (
                                    <Button key={ i } href={ row.href } className={ ( row?.current ? 'current' : '' ) } {...button}>{
                                        row.title
                                    }</Button>
                                );
                            })
                        }
                    </div>
                </nav>
            </div>
        </section>
    );

}