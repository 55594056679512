/**
*
*   Content Block
*       - A left/right content block + image or video
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import { gsap } from 'gsap';
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useState, useLayoutEffect, useRef } from 'react';

//Load Module
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

//Build the Element
export default function ContentBlock( props ){

    let creative    = '',
        content     = [],
        classes     = ['block','container'],
        ref         = useRef();

    //Set a Default Direction
    if( !props.direction )
        props.direction = 'left';

    //Allow it to be changed
    if( props.direction && ['left','right'].indexOf( props.direction ) > -1 )
        classes.push( props.direction );

    //Image
    if( props.image )
        creative = (
            // eslint-disable-next-line jsx-a11y/alt-text
            <div className="image creative">
                <Image alt="" { ...{ alt:'' , ...props.image } } />
            </div>
        );

    //Video
    if( props.video )
        creative = (
            <div className="video creative">
                <iframe { ...props.video } />
            </div>
        );

    //Title
    if( props.content.title )
        content.push(
            <header key={ props.id + '-header' }>
                <h2 dangerouslySetInnerHTML={{
                    __html: props.content.title
                }} />
            </header>
        );

    //Body
    if( props.content.body )
        content.push(
            <div key={ props.id + '-body' } dangerouslySetInnerHTML={{
                __html: props.content.body
            }} />
        );

    //Buttons
    if( props.content.buttons )
        content.push(
            <div className="buttons" key={ props.id + '-buttons' }>
                { props.content.buttons }
            </div>
        );

    //On Mount
    useGSAP(() => {

        //If this block doesn't have animation, just return
        if( !props.animate ) return;

        //Prepare the MatchMedia
        let mm          = gsap.matchMedia(),
            animations  = [{
                selector:   '.creative',
                init:       { yPercent: 0 },
                from:       { yPercent: 5 },
                to:         { yPercent: -5 },
            },{
                selector:   '.content',
                init:       { xPercent: 0 },
                from:       { xPercent: 1 },
                to:         { xPercent: -1 },
            }];

        //
        mm.add('(min-width: 1100px)', context => {

            //The Container
            let $container  = ref.current;

            //Loop through
            animations.forEach( ({ selector , init , from , to }) => {

                //Prepare timeline
                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger:                $container,
                        invalidateOnRefresh:    true,
                        scrub:                  true,
                        start:                  'top center',
                        end:                    () => "+=" + $container.offsetHeight
                    }
                });

                //Initiate
                timeline.fromTo( selector , from , to );

            });


        });

        //
        mm.add('(max-width: 800px)', context => {

            //The Container
            let $container  = ref.current;

            //Loop through
            [ animations[0] ].forEach( ({ selector , init , from , to }) => {

                //Prepare timeline
                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger:                $container,
                        invalidateOnRefresh:    true,
                        scrub:                  true,
                        start:                  'top center',
                        end:                    () => "+=" + $container.offsetHeight
                    }
                });

                //Initiate
                timeline.fromTo( selector + ' img' , { yPercent: 0 } , { yPercent: -20 } );

            });


        });

    }, { scope: ref });


    //Return
    return (
        <section id={ props.id } key={ props.id } ref={ ref } className={ classes.join(' ') }>
            <div>
                { creative }
                <div className="content">
                    <div>
                        { content }
                    </div>
                </div>
            </div>
        </section>
    );

}